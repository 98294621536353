import React from "react"
import Page from "../components/page/Page"
import { graphql } from "gatsby"
import "./libros.css"

export default props => {
  const librosEdges = props.data.allContentfulActividades.edges
  return (
    <Page class="actividades" slug="actividades" title="Actividades">
      <div className="list">
        <header className="list-header">
          <h1 className="title">Actividades</h1>
          <div className="subtitle">
            Mi participación en presentaciones de libros, conversatorios y
            debates
          </div>
        </header>
        <main className="list-main">
          {librosEdges.map((edge, i) => {
            return (
              <div className="item" key={i}>
                <div className="item-date">{edge.node.fecha}</div>
                <h2 className="item-title">{edge.node.titulo}</h2>

                <div
                  className="item-description"
                  dangerouslySetInnerHTML={{
                    __html: edge.node.descripcion.childMarkdownRemark.html,
                  }}
                />
              </div>
            )
          })}
        </main>
      </div>
    </Page>
  )
}

export const librosQuery = graphql`
  query {
    allContentfulActividades {
      edges {
        node {
          titulo
          fecha
          descripcion {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
